@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "AtypText_Regular";
  src: local("AtypText-Regular"), url("./assets/fonts/AtypText-Regular.ttf") format("truetype");
}
*{
  font-family: 'AtypText_Regular', sans-serif;
  font-style: normal;
}

.auth__title{
  font-weight: 600;
  font-size: 26px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 30px;
  color: #000032;
}

/* input without props */

.text-input{
  width: 100%;
  margin-bottom: 16px;
}
.text-input > label{
  width: 100%;
  position: relative;
}
.text-input > label > input{
  width: 100%;
  height: 50px;
  padding: 0 14px;
  background: #FFFFFF;
  box-shadow: 0 0.952381px 2.85714px rgba(50, 50, 93, 0.15), 0 0.952381px 0 rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50);
}
.text-input > label > input::placeholder{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.5);
}
.text-input > p{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 50, 0.5);
  margin-bottom: 4px;
}
.text-input > span{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 50, 0.5);
  margin-top: 4px;
}
.text-input > span:before{
  content: '*';
}

/* text input with props */
.text-input_with-props > label > input{
  padding: 0 40px 0 14px;
}
/* text input with border */
.text-input_with-border > label > input{
  border: 1px solid rgba(0, 0, 50, 0.5);
}
.text-input_40px > label > input{
  height: 40px;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root, .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 10px !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: 1px solid rgba(0, 0, 50, 0.5) !important;
}

.default-select{
  width: 100%;
  margin-bottom: 16px;
}
.default-select > select{
  width: 100%;
  height: 50px;
  padding: 0 14px;
  background: #FFFFFF;
  box-shadow: 0 0.952381px 2.85714px rgba(50, 50, 93, 0.15), 0 0.952381px 0 rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50);
}
.default-select > select::placeholder{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.5);
}
.default-select > p{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 50, 0.5);
  margin-bottom: 4px;
}
.default-select > span{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 50, 0.5);
  margin-top: 4px;
}
.default-select > span:before{
  content: '*';
}

/* default select with border */
.default-select_with-border > select{
  border: 1px solid rgba(0, 0, 50, 0.5);
}
/* default select 40px */

.default-select_40px > select{
  height: 40px;
}

/* text input icon button */

.text-input_icon_button{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top:50%;
  transform:translate(22%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-input_icon_button:hover{
  background: rgba(98, 105, 119, 0.18);
}

.text-input_icon_button > label{
  position: relative;
}
.text-input_icon_button > label > input{
  font-size: 0;
  border: none;
  background: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

/* roll back icon button */

.roll-back_button{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  left: 42px;
  top: 63px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.roll-back_button:hover{
  background: rgba(98, 105, 119, 0.18);
}

/* default submit button */

.submit-button_blue{
  min-width: 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 0 14px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0061C2;
  border-radius: 10px;
}
.submit-button_blue:hover{
  background: #00285A;
}
.submit-button_blue_small{
  font-size: 12px;
  line-height: 16px;
}

.submit-button_white{
  min-width: 100px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000032;
  padding:0 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0061C2;
  border-radius: 10px;
  cursor: pointer;
}
.submit-button_white_small{
  font-size: 12px;
  line-height: 16px;
}

.layoutWrapper{
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #F7F8FC;
}
.componentMainWrapper{
  max-width: 1440px;
  width: 100%;
  padding: 110px 40px 0 340px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.header{
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}
.header__mainBar{
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  padding: 0 40px 0 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-mainBar__user-greeting-text{
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000032;
}
.header-mainBar__exchange-text{
  background: #FFFFFF;
  border: 1px solid #19BD81;
  border-radius: 10px;
  padding: 0 14px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.header-mainBar__exchange-text p{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 50, 0.8);
}
.header-mainBar__exchange-text span{
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000032;
}
.header__avatar{
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}
.header__sideBar{
  width: 300px;
  height: 100vh;
  background: #FFFFFF;
  border-radius: 0 20px 20px 0;
  position: fixed;
  top: 0;
  left: 0;
}
.header-sideBar__links{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100% - 122px);
  border-bottom: 1px solid rgba(0, 0, 50, 0.5);
  position: relative;
}
.header-sideBar__links > div:first-child::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.header-sideBar__links > div:first-child, body{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.header-sideBar__link{
  width: 100%;
  height: 70px;
  padding-left: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  position: relative;
}
.header-sideBar__link span{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000032;
}
.header-sideBar__link_exit{
  position: absolute;
  bottom: -80px;
}
.header-sideBar__link:hover{
  background: #F7F8FC;
  border-radius: 0 8px 8px 0;
  border-right: 10px solid transparent;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #0061C2 0%, #19BD81 100%);
}
.header-sideBar__link_active{
  background: #F7F8FC;
  border-radius: 0 8px 8px 0;
  border-right: 10px solid transparent;
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #0061C2 0%, #19BD81 100%);
}
.page__title{
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  color: #000032;
}
.home-page__statuses{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.status-button{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  padding: 15px;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
}
.status-button div:first-child{
  background: rgba(0, 97, 194, 0.2);
  border-radius: 10px;
}
.status-button div:last-child{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.status-button div:last-child > p{
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.4px;
  color: #000032;
  white-space: nowrap;
}
.status-button div:last-child > span{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 50, 0.5);
}

.status-button:hover{
  box-shadow: 0 4px 26px rgba(0, 40, 90, 0.2);
}
.status-button_selected{
  background: #00285A;
  box-shadow: none;
}
.status-button_selected div:first-child{
  background: #FFFFFF;
}
.status-button_selected div:last-child > p{
  color: #FFFFFF;
}
.status-button_selected div:last-child > span{
  color: rgba(255, 255, 255, 0.7);
}

/* filter inputs */

.filter-input{
  width: 166px;
  background: white;
}
.filter-input > label{
  width: 100%;
}
.filter-input > label > input{
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: #FFFFFF;
  box-shadow: 0 0 17px -1px rgba(0, 40, 90, 0.1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #00285A;
}
.filter-input > label > input::placeholder{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #525F7F;
}
.filter-input > p{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 50, 0.5);
  margin-bottom: 4px;
}
.filter-input > span{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 50, 0.5);
  margin-top: 4px;
}
.filter-input > span:before{
  content: '*';
}


.filter-input_search{
  width: 324px;
}
.filter-input_search > label{
  width: 100%;
  position: relative;
}
.filter-input_search > label > input{
  width: 100%;
  height: 40px;
  padding: 0 60px 0 20px;
  background: #FFFFFF;
  box-shadow: 0 0 17px -1px rgba(0, 40, 90, 0.1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #00285A;
}
.filter-input_search > label > input::placeholder{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.4px;
  color: #525F7F;
}
.filter-input_search > p{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 50, 0.5);
  margin-bottom: 4px;
}
.filter-input_search > span{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 50, 0.5);
  margin-top: 4px;
}
.filter-input_search > span:before{
  content: '*';
}


/* page table */

.page_tableBox{
  width: 100%;
  box-shadow: 0 2px 12px rgba(16, 25, 43, 0.15);
  border-radius: 10px;
  overflow: hidden;
}
.page_table{
  /*overflow-x: hidden;*/
  overflow-y: hidden;
  background: white;
}
.page_table::-webkit-scrollbar {
  height: 4px;
  background-color: #f9f9fd;
}

.page_table::-webkit-scrollbar-thumb {
  background: rgba(0, 97, 194, 0.7);
  border-radius: 4px;
}

.page_table::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(16, 25, 43, 0.18);
}

.page_table table {
  border-collapse: collapse;
  border-radius: 5px;
  width: 100%;
}
.page_table thead{
  background: rgba(0, 20, 100, 0.9);
}
.page_table tbody{
  height: 50px;
}
.page-table__body_loader{
  position: relative;
}
.page-table__body_loader:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/images/Component 36.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
  content: "";
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.page_table td{
  border: 1px solid rgba(16, 25, 43, 0.15);
  padding: 11px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #000032;
  white-space: nowrap;
}
.page_table th {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border: 1px solid rgba(16, 25, 43, 0.15);
  padding: 14px 10px;
  text-align: left;
  color: white;
  white-space: nowrap;
}
.page_table td{
  color: #10192B;
}
.page_table tr:first-child th {
  border-top: 0;
}
.page_table tr:last-child td {
  /*border-bottom: 0;*/
}
.page_table tr td:first-child,
.page_table tr th:first-child {
  border-left: 0;
}
.page_table tr td:last-child,
.page_table tr th:last-child {
  border-right: 0;
}
.page_table tr:hover {
  background: rgba(0, 97, 194, 0.2);
}

.page_table input[type='checkbox']{
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid rgba(0, 97, 194, 0.2);
}


/* pagination */

.page-table__footer{
  width: 100%;
  border-top: 1px solid  rgba(16, 25, 43, 0.15);
  background: white;
}

.pagination {
  display: inline-block;
  padding: 20px 0;
}

.pagination span {
  color: black;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
  transition: background-color .3s;
  border-radius: 6px;
}

.pagination span.active {
  background: rgba(0, 97, 194, 0.8);
  color: white;
}

.pagination span:hover:not(.active) {background-color: #ddd;}

.selected-orders-count{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 50, 0.5);
}
.page-table__icon-button{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-table__icon-button:hover{
  background: rgba(98, 105, 119, 0.18);
}


/* loader */
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid  #0061C2; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.form-add__header p{
   font-weight: 400;
   font-size: 18px;
   line-height: 25px;
   color: #FFFFFF;
}
.form-add__header span{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

.form-add__rollBack-icon{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-add__rollBack-icon path{
  stroke: white;
}
.form-add__rollBack-icon:hover{
  background: rgba(98, 105, 119, 0.18);
}

.orderView-container div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 26px;
  margin-bottom: 20px;
}
.orderView-container p{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.6);
}
.orderView-container span{
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000032;
}
.orderView-container h1{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.5);
  border-bottom: 1px solid rgba(0, 0, 50, 0.5);
}

.orderView-container_status h1{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.5);
  border-bottom: 1px solid rgba(0, 0, 50, 0.5);
}

.orderView-container_statusWrapper{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.orderView-container_statusWrapper > div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.orderView-container_statusWrapper > div > p{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.6);
}
.orderView-container_statusWrapper > div > span{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000032;
}

/* notification table row */

.notifications__row p:first-child{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.notifications__row p:last-child{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 50, 0.5);
  text-overflow: ellipsis;
}
.notifications__row span{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 50, 0.5);
}
.notification-view__container{
  background: #FFFFFF;
  box-shadow: 4px 2px 15px rgba(71, 52, 154, 0.15);
  border-radius: 10px;
}
.notification-view__rollBack-icon{

}
.notification-view__rollBack-icon{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-view__rollBack-icon:hover{
  background: rgba(98, 105, 119, 0.18);
}
.notification-view__header span{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #444746;
}
.notification-view__message h1{
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: #000032;
}
.notification-view__message p{
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #444447;
}


.personal__avatar{
  font-weight: 400;
  font-size: 48px;
  line-height: 65px;
  color: #FFFFFF;
}
.personal__info div{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
}
.personal__info > div > p{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.7);
  margin-right: 17px;
}
.personal__info > div > span{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.7);
}
.personal__info div:first-child{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 22px;
}
.personal__info > div:first-child > p{
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000032;
  margin-bottom: 2px;
}
.personal__info > div:first-child > span{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 50, 0.7);
}
.personal__info div:last-child{
  margin-bottom: 0;
}
.personal-password-edit__button{
  min-width: 152px;
  height: 32px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border: 1px solid #000032;
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000032;
  cursor: pointer;
}
.personal-info-edit__button{
  min-width: 135px;
  height: 32px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  background: #0061C2;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  cursor: pointer;
}
.personal-info-edit__button:hover{
  background: #00285A;
}

.personal-password-edit__modal h1{
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
}

.passport__title{
  border-bottom: 1px solid rgba(0, 0, 50, 0.5);
}
.passport__title span{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 50, 0.5);
}

.passport__body{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
.passport-body__item{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.passport-body__item span:first-child{
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: rgba(0, 0, 50, 0.5);
  margin-bottom: 10px;
}
.passport-body__item span:last-child{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: white;
  margin-top: 10px;
  cursor: pointer;
  padding: 5px 10px;
  background: #0061C2;
  border-radius: 6px;
}
.passport-body__item span:last-child:hover{
  background: #00285A;
}
.passport-body__item div{
  height: 211px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.12);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.passport-body__item img{
  height: inherit;
}

.passport-body__item label{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 97, 194, 0.1);
  box-shadow: 0 1px 5px -1px rgba(50, 50, 93, 0.12);
  border-radius: 8px;
  gap: 10px;
  padding: 10px 12px;
  cursor: pointer;
}
.passport-body__item label p{
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000032;
}
.passport-body__item input{
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
  width: 0;
  height: 0;
  border: none;
}



.notificationAdd-checkbox input{
  width: 21px;
  height: 21px;
  background: rgba(0, 97, 194, 0.9);
  border-radius: 2px;
}
.notificationAdd-checkbox span{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000032;
}


.boxOffice__modalPrice span{
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: rgba(0, 0, 50, 0.9);
}


/* home page */

.statistic__buttons{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 28px;
}
.statistic__button {
  cursor: pointer;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #FFFFFF;
  box-shadow: 0 1px 7px -3px rgba(0, 40, 90, 0.13);
  border-radius: 10px;
}
.statistic__button p{
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000032;
  margin-bottom: 20px;
}
.statistic__button span{
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: #000032;
}
.statistic__button_selected{
  background: rgba(0, 97, 194, 0.1);
  box-shadow: 0 1px 7px -3px rgba(0, 40, 90, 0.13);
  border-radius: 10px;
}
.statistic__button_discount div{
  width: 100%;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid black;
}


.currencyInfo__item{
  width: 100%;
  padding: 20px 40px;
  background: rgba(25, 189, 129, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.currencyInfo__item span{
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: #000032;
  white-space: nowrap;
}
.currencyInfo__item p{
  font-weight: 600;
  font-size: 40px;
  line-height: 54px;
  color: #000032;
  white-space: nowrap;
}


.tariffInfo .currencyInfo__item{
  background: rgba(25, 51, 189, 0.1);
  border-radius: 20px;
}

.tariffInfo .currencyInfo__item_vip{
  background: rgba(252, 41, 58, 0.1);
}
.boxOffice__button{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #0061C2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.boxOffice__button:hover{
  background: #00285A;
}
